<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    
    <div>
      <!--<button #menubutton class="p-link layout-menu-button " (click)="layoutService.onMenuToggle()">
    <i [class]=" "></i>
  </button>-->

      <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
      </div>

    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <!-- <p-fieldset legend="Header">
      <router-outlet></router-outlet>
  </p-fieldset> -->

        <p-breadcrumb class="max-w-full" [model]="items"></p-breadcrumb>
        <p-card [style]="{ width: '100%', height: '80vh', overflow: ' auto'}">
          <router-outlet></router-outlet>
        </p-card>

      </div>
        <app-footer></app-footer>
    </div>
    <!-- <app-config></app-config> -->
    <div class="layout-mask"></div>
</div>
