
<div class="infoEnseigne" *ngIf="enseigneModif">

  <div class="infoEnseigneDiv">
    <img [src]="enseigneModif.Image" style="max-height: 15em; border-radius: 10px; border: 1px solid black; " />
    <div>
      <input class="file-field" #imageInput type="file" name="file" id="file" (change)="onFileChange($event)" accept=".jpg,.jpeg,.png,.bmp" />
      <label for="file" class="file-label">
        <figure>
          <i class="fa fa-upload"></i>
        </figure>
        <span>Importer votre logo&hellip;</span>
      </label>
    </div>
  </div>

</div>

<div class="card flex flex-column justify-content-around align-items-center">

  <div class="flex flex-row justify-content-around align-items-center" style="width: 100%; margin-bottom: 1em;">
    <div class="childrenInfoEnseigne">
      <!--<div class="infoEnseigneDiv">
    <mat-label>Code d'attribution des points de fidélité </mat-label>
    <input type="text" pInputText [(ngModel)]="enseigneModif.Code" maxlength="6">
  </div>
  <div class="infoEnseigneDiv">
    <mat-label>Code de validation des gains </mat-label>
    <input type="text" pInputText [(ngModel)]="enseigneModif.CodeValiationGain" maxlength="6buttonEnseigneDiv
  </div>-->
      <div class="infoEnseigneDiv">
        <mat-label>Moyens de Paiement</mat-label>
        <input type="text" pInputText [(ngModel)]="enseigneModif.MoyenPaiement" style="width: 20em;" />
      </div>
      <div class="infoEnseigneDiv">
        <mat-label>Services</mat-label>
        <input type="text" pInputText [(ngModel)]="enseigneModif.Service" style="width: 20em;" />
      </div>
      <div class="infoEnseigneDiv">
        <mat-label>Site Web</mat-label>
        <input type="text" pInputText [(ngModel)]="enseigneModif.SiteWeb" style="width: 20em;" />
      </div>
      <div class="infoEnseigneDiv">
        <mat-label>Téléphone</mat-label>
        <input type="text" pInputText matInput type="tel" [(ngModel)]="enseigneModif.Telephone" style="width: 20em;" />
      </div>
      <div class="infoEnseigneDiv">
        <mat-label>Twitter</mat-label>
        <input type="text" pInputText [(ngModel)]="enseigneModif.Twitter" style="width: 20em;" />
      </div>
      <div class="infoEnseigneDiv">
        <mat-label>Facebook</mat-label>
        <input type="text" pInputText [(ngModel)]="enseigneModif.Facebook" style="width: 20em;" />
      </div>
      <div class="infoEnseigneDiv">
        <mat-label>Instagram</mat-label>
        <input type="text" pInputText [(ngModel)]="enseigneModif.Instagram" style="width: 20em;" />
      </div>
    </div>

    <div class="parentDivHorrairesEnseigne" *ngIf="enseigneModif && enseigneModif.Horraires">
      <h4>Horaires d'ouverture</h4>

      <p-steps [model]="items" [(activeIndex)]="activeIndex" styleClass="custom-stepper" [readonly]="false"></p-steps>

      <ng-container *ngFor="let hor of enseigneModif.Horraires; let idx = index;">
        <div *ngIf="activeIndex == idx" class="flex flex-column justify-content-around align-items-center">
          <div class="flex flex-column justify-content-around align-items-center">
            <div>
              <h3>Matin</h3>
              <div>
                <div class="infoHorrairesEnseigneDiv">
                  <label class="heureLabel">Ouverture </label>
                  <input class="inputHoraire" pInputText type="text" [(ngModel)]="hor.HeureDebutAm" maxlength="6">
                </div>
                <div class="infoHorrairesEnseigneDiv">
                  <label class="heureLabel">Fermeture </label>
                  <input class="inputHoraire" pInputText type="text" [(ngModel)]="hor.HeureFinAm" maxlength="6">
                </div>
              </div>
            </div>

            <div>
              <h3>Après-midi</h3>
              <div class="infoHorrairesEnseigneDiv">
                <label class="heureLabel">Ouverture </label>
                <input class="inputHoraire" pInputText type="text" [(ngModel)]="hor.HeureDebutPm" maxlength="6">
              </div>
              <div class="infoHorrairesEnseigneDiv">
                <label class="heureLabel">Fermeture </label>
                <input class="inputHoraire" pInputText type="text" [(ngModel)]="hor.HeureFinPm" maxlength="6">
              </div>
            </div>
          </div>

          <div class="flex flex-row justify-content-around align-items-center" style="width: 50%">
            <button pButton type="button" label="Retour" (click)="decrementIndex()" *ngIf="activeIndex > 0" style="margin-right: 1em;"></button>
            <button pButton type="button" label="Suivant" (click)="incrementIndex()" *ngIf="activeIndex < enseigneModif.Horraires.length - 1"></button>
          </div>

        </div>


      </ng-container>


    </div>
  </div>


  <div class="buttonEnseigneDiv">
    <button mat-raised-button color="warn" (click)="annulerModif()" style="width: 5em; text-align: center; margin-right: 1em; color: red">Annuler</button>
    <button mat-raised-button color="primary" (click)="submit()" style="width: 10em; text-align: center; background-color: darkblue; color:white;">Enregistrer</button>
  </div>

</div>


