<div class="__className_31ea31">
  <div class="MuiBox-root css-1w25uqh"><div class="MuiBox-root css-1gyaa3l"></div><div class="MuiBox-root css-1o5t64"></div><div class="MuiBox-root css-1y7rz8s"></div><div class="MuiBox-root css-veazct"></div><div class="MuiBox-root css-5py7bo"></div><div class="MuiBox-root css-1m8i1m3"></div><div class="MuiBox-root css-1w40gb8"></div><div class="MuiBox-root css-nt56a4"></div><div class="MuiBox-root css-chz1x5"></div><div class="MuiBox-root css-1u6c8i2"></div><div class="MuiBox-root css-8q034s"></div><div class="MuiBox-root css-youzr"></div><div class="MuiBox-root css-1fuscc8"></div></div>
</div>

<div class="flex justify-content-center align-items-center h-100 w-100 parrentLoginDiv">
  <div class="divCard card">
    <div class="divImg">
      <div class="imageLogo"></div>
    </div>
    <div class="divP">
      <h1 class="titreLogin">Connexion</h1>
      <div class="divPInput">
        <div class="divInput">
          <input type="text" class="inputLogin" placeholder="Login" required [(ngModel)]="login" name="login">
        </div>
        <div style="position: relative;">
          <input [type]="hidePassword ? 'password' : 'text'" class="inputLogin" placeholder="Mot de passe" required [(ngModel)]="password" minlength="8" name="password">
          <a class="type-toggle" (click)="hidePassword = !hidePassword" style="position: absolute; right: 0.6em; top: 0.4em; ">
            <div [hidden]="hidePassword">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
              </svg>
            </div>
            <div [hidden]="!hidePassword">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
              </svg>
            </div>
          </a>
        </div>
        <div class="mdpOublie">
          <a href="#" (click)="open(1)">Mot de passe oublié ?</a>
        </div>
      </div>

      <div class="divPButton">
        <span *ngIf="errorLogin" style="color:red; margin-left: 20px;">{{erroLoginMessage}}</span>
        <input type="submit" value="Se connecter" class="loginBtn" (click)="onSubmit()" [disabled]="password.length < 8 || login.length < 0 ">
        <!--<div class="inscription">
          <a href="#" (click)="open(0)">Inscription</a>
        </div>-->
      </div>
    </div>

  </div>
</div>

<p-dialog header="Connexion" [(visible)]="showDialog" [style]="{width: '10vw'}">
  <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
  <p>
    Chargement en cours !!!
  </p>
</p-dialog>
