import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumbItemsSubject = new BehaviorSubject<MenuItem[]>([]);
  breadcrumbItems$ = this.breadcrumbItemsSubject.asObservable();

  setBreadcrumb(items: MenuItem[]) {
    const home: MenuItem = { label: 'Accueil', routerLink: 'Dashboard' };
    this.breadcrumbItemsSubject.next([home, ...items]);

  }

  constructor() { }
}
