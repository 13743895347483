import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Options } from 'ng5-slider';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { DialogComponent } from '../dialog/dialog.component';
import { urlConfig } from '../service/urlConfig.service';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
//import * as L from 'leaflet';



@Component({
  selector: 'app-administration-campagne',
  templateUrl: './administration-campagne.component.html',
  styleUrls: ['./administration-campagne.component.scss']
})
export class AdministrationCampagneComponent implements OnInit {

  //urlCommand: string = "https://localhost:5005/api/Command/";
  //urlCommand: string = "https://207.180.246.59:57429/api/Command/";

  lstCampagnesArchives = [];
  lstCampagnesActives = [];
  lstCampagnesPauses = [];

  selectedIndex = 0;

  campagnesSelectedDB: any;
  campagnesSelected: any;
  statistique: any;
  selectedStatus: string;

  timeDebutCampagne?: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  timeFinCampagne?: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };

  circle: any;
  LatLngmarker: any;
  range: number = 30;
  myMap: any;

  options: Options = {
    floor: 0,
    ceil: 3000,
    showSelectionBar: true,
    selectionBarGradient: {
      from: '#019FC2',
      to: '#0E3A4B'
    },
    getPointerColor: (value: number): string => {
      if (value >= 0 && value < 1500) {
        return '#019FC2';
      }
      if (value >= 1500 && value <= 3000) {
        return '#0E3A4B';
      }
    }
  };

  displayedColumns: string[] = ['heure', 'nbPush', 'acceptations', 'visionnage', 'clientMagasin','coutProspect'];
  dataSource = [
    { heure: '18H00', nbPush: 1237, acceptations: 1237, visionnage: 1200, clientMagasin: 1100, coutProspect: '3.11€' },
    { heure: '17H00', nbPush: 486, acceptations: 443, visionnage: 443, clientMagasin: 443, coutProspect: '1.30€' },
    { heure: '16H00', nbPush: 492, acceptations: 485, visionnage: 485, clientMagasin: 345, coutProspect: '3.34€' },
    { heure: '15H00', nbPush: 344, acceptations: 400, visionnage: 400, clientMagasin: 400, coutProspect: '5.40€' },
    { heure: '14H00', nbPush: 346, acceptations: 465, visionnage: 465, clientMagasin: 465, coutProspect: '7.21€' },
    { heure: '13H00', nbPush: 1239, acceptations: 1500, visionnage: 1500, clientMagasin: 1500, coutProspect: '2.00€' },
    { heure: '12H00', nbPush: 2358, acceptations: 4006, visionnage: 4006, clientMagasin: 4006, coutProspect: '1.34€' },
    { heure: '11H00', nbPush: 1560, acceptations: 1599, visionnage: 1599, clientMagasin: 1599, coutProspect: '7.98€' },
    { heure: '10H00', nbPush: 985, acceptations: 1000, visionnage: 1599, clientMagasin: 1599, coutProspect: '6.56€' },
    { heure: '09H00', nbPush: 245, acceptations: 297, visionnage: 1599, clientMagasin: 1599, coutProspect: '1.34€' },
  ];


  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit(): void {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });
    var usr = { "login": NavMenuComponent.userLog };

    //actif: false
    //capping: "1"
    //cappingCampagnes: []
    //coutParVue: "1"
    //dateDebut: "2020-12-31T00:00:00"
    //dateFin: "2021-01-30T00:00:00"
    //filtre: ""
    //heureDebut: null
    //heureFin: null
    //id: 1
    //idUser: 10025
    //idUserNavigation: null
    //montant: "1"
    //nom: "Solde 2021"
    //nombreEnvoie: "1"
    //pause: false
    //pub: "00000000-0000-0000-0000-000000000000"
    //pubNavigation: null
    //pubs: []

    this.http.post(urlConfig.getUrl("command") + "getCampagneForUser", usr, { headers: headers }).subscribe((res: any) => {

      if (res.success) {
        this.lstCampagnesArchives = res.data.reduce((c: any, i: any) => { if (i.Actif == false) { c.push(i); } return c; }, []);
        this.lstCampagnesActives = res.data.reduce((c: any, i: any) => { if (i.Actif == true && i.Pause == false) { c.push(i); } return c; }, []);
        this.lstCampagnesPauses = res.data.reduce((c: any, i: any) => { if (i.Actif == true && i.Pause == true) { c.push(i); } return c; }, []);

        if (this.lstCampagnesActives.length > 0) {
          this.selectCampagne(this.lstCampagnesActives[0]);
          this.selectedIndex = 0;
        }
        else if (this.lstCampagnesPauses.length > 0) {
          this.selectCampagne(this.lstCampagnesPauses[0]);
          this.selectedIndex = 1;
        }
        else {
          this.selectCampagne(this.lstCampagnesArchives[0]);
          this.selectedIndex = 2;
        }

        if (this.campagnesSelected) {
          this.timeDebutCampagne = this.campagnesSelected.HeureDebut != null ? this.stringToHeure(this.campagnesSelected.HeureDebut) : null;
          this.timeFinCampagne = this.campagnesSelected.HeureFin != null ? this.stringToHeure(this.campagnesSelected.HeureFin) : null;


        }
        

      }
    });

  }

  stringToHeure(item: string): NgbTimeStruct {
    var idxDebut = item.indexOf(':');
    var idxFin = item.lastIndexOf(':');

    var h: NgbTimeStruct = { hour: parseInt(item.substring(0, idxDebut)), minute: parseInt(item.substring(idxFin - 2, idxFin)), second: parseInt(item.substring(idxFin + 1)) };
    
    return h;
  }

  selectCampagne(item:any) {
    if (item) {

      this.campagnesSelected = item;
      const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });
      var pub = { "Id": item.Pub };

      this.timeDebutCampagne = this.campagnesSelected.HeureDebut != null ? this.stringToHeure(this.campagnesSelected.HeureDebut) : null;
      this.timeFinCampagne = this.campagnesSelected.HeureFin != null ? this.stringToHeure(this.campagnesSelected.HeureFin) : null;

      this.http.post(urlConfig.getUrl("command") + "getPub", pub, { headers: headers }).subscribe((res: any) => {

        if (res.success) {

          this.campagnesSelected.PubNavigation = res.data;
          this.selectedStatus = this.campagnesSelected.Actif ? (!this.campagnesSelected.Pause ? "1" : "2") : "3";

          var lat = this.campagnesSelected.PubNavigation.CoordonneeNavigation.Latitude;
          var long = this.campagnesSelected.PubNavigation.CoordonneeNavigation.Longitude;

          console.log("lat", lat);
          console.log("long", long);

          var r = this.campagnesSelected.PubNavigation.Distance > 0 ? 31 - ((this.campagnesSelected.PubNavigation.Distance / 1000) * 4) : 19 ;

          if (this.myMap != undefined) {
            //this.myMap.remove();
            this.myMap.removeLayer(this.LatLngmarker);
            this.myMap.removeLayer(this.circle)
            this.myMap.setView([lat, long], r);

          }
          else {
            //this.myMap = L.map('map').setView([lat, long], r);

          }
          
          //myMap.addControl(searchControl);

          //L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          //  attribution: 'Flytagger'
          //}).addTo(this.myMap);


          //const myIcon = L.icon({
          //  iconUrl: "../assets/images/pointeur2.svg",
          //  iconSize: [100, 100],
          //  iconAnchor: [50, 80],
          //  popupAnchor: [0, -72]
          //});


          //this.circle = L.circleMarker([lat, long], { radius: this.campagnesSelected.PubNavigation.Distance });

          //this.circle.addTo(this.myMap);

          //this.LatLngmarker = L.marker([lat, long], { icon: myIcon, draggable: false });
          //this.LatLngmarker.addTo(this.myMap);

          //this.statistique = this.campagnesSelected.PubNavigation.Statistique[0];

          //this.campagnesSelectedDB = Object.assign({}, this.campagnesSelected);

          //this.http.post(this.urlCommand + "getStatFromPub", pub, { headers: headers }).subscribe((resu: any) => {
          //  if (resu.success) {
          //    this.statistique = resu.data;

          //    console.log("campagnesSelected", this.campagnesSelected);
          //    console.log("statistique", this.statistique);

          //  }
          //});

        }
      });
    }
    
  }

  annuler() {
    this.campagnesSelected = Object.assign({}, this.campagnesSelectedDB);

  }

  save() {
    this.dialog.open(DialogComponent, {
      data: {
        title: "Enregistrement des modifications",
        message: 'Enregistrement en cours !!!',
        enableLoader: true,
        congrat: false
      }
    });

    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

    var dd = new Date(this.campagnesSelected.DateDebut);
    dd.setDate(dd.getDate() + 1);
    this.campagnesSelected.DateDebut = dd;

    var df = new Date(this.campagnesSelected.DateFin);
    //df.setDate(dd.getDate() + 1);


    this.campagnesSelected.DateFin = df;

    if (this.timeDebutCampagne != null) {
      this.campagnesSelected.HeureDebut = this.timeDebutCampagne ? "" + this.timeDebutCampagne.hour + ":" + this.timeDebutCampagne.minute + ":" + this.timeDebutCampagne.second : null;
    }

    if (this.timeFinCampagne != null) {
      this.campagnesSelected.HeureFin = this.timeFinCampagne ? "" + this.timeFinCampagne.hour + ":" + this.timeFinCampagne.minute + ":" + this.timeFinCampagne.second : null;
    }

    var CoordonneeNavigationData = {
      Id: this.campagnesSelected.PubNavigation.CoordonneeNavigation.Id,
      Latitude: this.campagnesSelected.PubNavigation.CoordonneeNavigation.Latitude,
      Longitude: this.campagnesSelected.PubNavigation.CoordonneeNavigation.Longitude,
      Pub: this.campagnesSelected.PubNavigation.CoordonneeNavigation.Pub,
      User: this.campagnesSelected.PubNavigation.CoordonneeNavigation.User
    };

    var statData = {
      Id: this.campagnesSelected.PubNavigation.Statistique.Id,
      NbEnvoie: this.campagnesSelected.PubNavigation.Statistique.NbEnvoie,
      NbNotifcation: this.campagnesSelected.PubNavigation.Statistique.NbNotifcation,
      NbVue: this.campagnesSelected.PubNavigation.Statistique.NbVue,
      Pub: this.campagnesSelected.PubNavigation.Statistique.Pub,
      PubNavigation: this.campagnesSelected.PubNavigation.Statistique.PubNavigation,
    };

    var pubData = {
      Id: this.campagnesSelected.PubNavigation.Id,
      Titre: this.campagnesSelected.PubNavigation.Titre,
      Body: this.campagnesSelected.PubNavigation.Body,
      ImageData: this.campagnesSelected.PubNavigation.ImageData,
      Distance: this.campagnesSelected.PubNavigation.Distance,
      Notify: this.campagnesSelected.PubNavigation.Notify,
      Nom: this.campagnesSelected.PubNavigation.Nom,
      NbPlay: this.campagnesSelected.PubNavigation.NbPlay,
      NbEnvoie: this.campagnesSelected.PubNavigation.NbEnvoie,
      ImageData2: this.campagnesSelected.PubNavigation.ImageData2,
      Coordonnee: this.campagnesSelected.PubNavigation.Coordonnee,
      Prix: this.campagnesSelected.PubNavigation.Prix,
      IdCampagne: this.campagnesSelected.PubNavigation.IdCampagne,
      CoordonneeNavigation: CoordonneeNavigationData,
      IdCampagneNavigation: this.campagnesSelected.PubNavigation.IdCampagneNavigation,
      Campagne: this.campagnesSelected.PubNavigation.Campagne,
      FiltrePub: this.campagnesSelected.PubNavigation.FiltrePub,
      GainUser: this.campagnesSelected.PubNavigation.GainUser,
      Statistique: this.campagnesSelected.PubNavigation.Statistique
    };

    pubData.Statistique[0] = statData;


    this.campagnesSelected.PubNavigation = pubData;

    //if (this.campagnesSelected.PubNavigation.$id != null) {
    //  this.campagnesSelected.PubNavigation.$id = this.campagnesSelected.PubNavigation.Id;
    //}

    switch (this.selectedStatus) {
      case '1':
        this.campagnesSelected.Actif = true;
        this.campagnesSelected.Pause = false;
        break;
      case '2':
        this.campagnesSelected.Actif = true;
        this.campagnesSelected.Pause = true;
        break;
      case '3':
        this.campagnesSelected.Actif = false;
        this.campagnesSelected.Pause = false;
        break;

    }


    var campagneData = {
      Actif: this.campagnesSelected.Actif,
      Capping: this.campagnesSelected.Capping,
      CappingCampagnes: this.campagnesSelected.CappingCampagnes,
      CoutParVue: this.campagnesSelected.CoutParVue,
      DateDebut: this.campagnesSelected.DateDebut,
      DateFin: this.campagnesSelected.DateFin,
      Filtre: this.campagnesSelected.Filtre,
      HeureDebut: this.campagnesSelected.HeureDebut,
      HeureFin: this.campagnesSelected.HeureFin,
      Id: this.campagnesSelected.Id,
      IdUser: this.campagnesSelected.IdUser,
      IdUserNavigation: this.campagnesSelected.IdUserNavigation,
      Montant: this.campagnesSelected.Montant,
      Nom: this.campagnesSelected.Nom,
      NombreEnvoie: this.campagnesSelected.NombreEnvoie,
      Pause: this.campagnesSelected.Pause,
      Pub: this.campagnesSelected.Pub,
      PubNavigation: this.campagnesSelected.PubNavigation,
      Pubs: this.campagnesSelected.Pubs
    };

    this.campagnesSelected = campagneData;

    this.http.post(urlConfig.getUrl("command") + "putCampagne", this.campagnesSelected, { headers: headers }).subscribe((resu: any) => {
      if (resu == true) {
        this.campagnesSelectedDB = Object.assign({}, this.campagnesSelected);

        this.ngOnInit();
        this.dialog.closeAll();

        this.dialog.open(DialogComponent, {
          data: {
            title: "Campagne modifié",
            message: 'La campagne a bien été modifié !!!',
            enableLoader: false,
            congrat: true
          }
        });

        setTimeout(() => { this.dialog.closeAll(); }, 3000);

      }
      else {
        this.dialog.closeAll();

        this.dialog.open(DialogComponent, {
          data: {
            title: "Erreur lors de la modification",
            message: 'Une erreur est survenue lors de la modification de la campagnet !!!',
            enableLoader: false,
            congrat: false
          }
        });

        setTimeout(() => { this.dialog.closeAll(); }, 3000);
      }
    }, error => {
      this.dialog.closeAll();

      this.dialog.open(DialogComponent, {
        data: {
          title: "Erreur lors de la modification",
          message: 'Une erreur est survenue lors de la modification de la campagne !!!',
          enableLoader: false,
          congrat: false
        }
      });

      setTimeout(() => { this.dialog.closeAll(); }, 3000);

    });
  }
}
