<div class="card flex flex-column justify-content-around align-items-center" style="width: 100%; box-shadow: 0px 4px 11px -4px #000; margin-bottom: 10px;" *ngIf="feeBack">
    <div class="txtTitleDiv">
      Statistiques du jour
    </div>
    <div class="flex flex-row justify-content-around align-items-center" style="width: 100%;">
      <span></span>
      <div class="infoStat">
        <p-chart type="bar" [data]="dataClientsAujourdhuiParHeure" class="chart-custom"></p-chart>
        <div >
          Nouveaux clients au total : <span class="lowStockColor"> {{sumArrayElements(feeBack.ClientsAujourdhuiParHeure)}} </span>
        </div>
      </div>

      <div class="infoStat">
        <p-chart type="bar" [data]="dataPointsAttribuesAujourdhuiParHeure" class="chart-custom"></p-chart>
        <div >
          Nombre de points total : <span class="lowStockColor"> {{sumArrayElements(feeBack.PointsAttribuesAujourdhuiParHeure)}} </span>
        </div>
      </div>

      <div class="infoStat">
        <p-chart type="bar" [data]="dataGainsConsommesAujourdhuiParHeure" class="chart-custom"></p-chart>
        <div >
          Nombre de lots retiré au total : <span class="lowStockColor"> {{sumArrayElements(feeBack.GainsConsommesAujourdhuiParHeure)}} </span>
        </div>
      </div>
    </div>
  </div>


<div class="card flex flex-column justify-content-around align-items-center" style="width: 100%; box-shadow: 0px 4px 11px -4px #000; padding: 10px 1px 10px 1px !important;" *ngIf="feeBack">
  <div class="txtTitleDiv">
    Statistiques globales
  </div>
  <div class="flex flex-row justify-content-around align-items-center flex-wrap" style="width: 100%;">
    <div class="infoStat">
      <p-chart type="line" [data]="dataClientsParJour" class="chart-custom"></p-chart>
    </div>
    <div class="infoStat">
      <p-chart type="line" [data]="dataClientsParSemaine" class="chart-custom"></p-chart>
    </div>
    <div class="infoStat">
      <p-chart type="line" [data]="dataClientsParMois" class="chart-custom"></p-chart>
    </div>
  </div>

  <div class="flex flex-row justify-content-around align-items-center flex-wrap" style="width: 100%;">
    <div class="infoStat">
      <p-chart type="line" [data]="dataGainsParJour" class="chart-custom"></p-chart>
      <div >
        Nombre de clients au total : <span class="lowStockColor"> {{feeBack.ngClientTotal}} </span>
      </div>
    </div>
    <div class="infoStat">
      <p-chart type="bar" [data]="dataGainsConsommesParJour" class="chart-custom"></p-chart>
      <div >
        Nombre de lots gagnés au total : <span class="lowStockColor"> {{feeBack.nbGainGagne}} </span>
      </div>
    </div>
    <div class="infoStat">
      <p-chart type="bar" [data]="dataPointsParJour" class="chart-custom"></p-chart>
      <div >
        Nombre de points gagnés au total : <span class="lowStockColor"> {{feeBack.pointCumuler}} </span>
      </div>
    </div>

  </div>

</div>

<!-- <div class="card flex flex-row justify-content-around align-items-center flex-wrap" style="width: 100%;" *ngIf="feeBack">

  <div class="tableStatRow">
    <div>Clients aujourd'hui </div>
    <div>{{feeBack.nbClientJour}}</div>
  </div>

  <div class="tableStatRow">
    <div>Clients cette semaine </div>
    <div>{{feeBack.nbClientSemaine}}</div>
  </div>

  <div class="tableStatRow">
    <div>Clients ce mois-ci </div>
    <div>{{feeBack.nbClientMois}}</div>
  </div>

  <div class="tableStatRow">
    <div>Clients cette annee </div>
    <div>{{feeBack.nbClientAnnee}}</div>
  </div>

  <div class="tableStatRow">
    <div>Clients totals </div>
    <div>{{feeBack.ngClientTotal}}</div>
  </div>

  <div class="tableStatRow">
    <div>Clients abonnés </div>
    <div>{{feeBack.nbAbonnee}}</div>
  </div>

  <div class="tableStatRow">
    <div>Comptes vendeurs </div>
    <div>{{feeBack.nbCompteVendeur}}</div>
  </div>

  <div class="tableStatRow">
    <div>Gains gagnés </div>
    <div>{{feeBack.nbGainGagne}}</div>
  </div>

  <div class="tableStatRow">
    <div>Gains consommés </div>
    <div>{{feeBack.nbGainConsomme}}</div>
  </div>

  <div class="tableStatRow">
    <div>Points attribués </div>
    <div>{{feeBack.pointCumuler}}</div>
  </div>

</div> -->
