<div class="parentDivVendeur">
  <div class="parentDiv">
    <div style="text-align: justify; width: 70%; margin: auto; margin-bottom: 2em;">
      Grâce à notre plateforme, optimisez la gestion de votre commerce en créant des comptes dédiés pour vos vendeurs. Ces comptes personnalisés leur offrent un accès exclusif à notre application professionnelle, conçue pour simplifier et améliorer le quotidien des commerçants. En dotant vos vendeurs de ces outils performants, vous leur permettez de bénéficier d'une interface intuitive et adaptée à leurs besoins. Notre application, méticuleusement conçue, assure que chaque utilisateur accède uniquement aux fonctionnalités nécessaires pour mener à bien ses missions. Investissez dans l'efficacité et la croissance de votre activité commerciale en tirant pleinement parti des solutions innovantes que nous mettons à votre disposition
    </div>

    <div class="card" style="width: 100%; margin-top:1em;">

      <p-table [value]="lstVendeur" [scrollable]="true" scrollHeight="29em" editMode="row" dataKey="Nom">
        <ng-template pTemplate="header">
          <tr>
            <th>Login</th>
            <th>Mot de passe</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Adresse Mail</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vendeur let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="vendeur">
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="vendeur.Login" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{vendeur.Login}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="vendeur.Password" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{vendeur.Password}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="vendeur.Nom">
                </ng-template>
                <ng-template pTemplate="output">
                  {{vendeur.Nom}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="vendeur.Prenom">
                </ng-template>
                <ng-template pTemplate="output">
                  {{vendeur.Prenom}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="vendeur.Mail" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{vendeur.Mail}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td>
              <div class="flex align-items-center justify-content-center gap-2">
                <!--<button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(vendeur)" class="p-button-rounded p-button-text"></button>
                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(vendeur)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(vendeur, ri)" class="p-button-rounded p-button-text p-button-danger"></button>-->
                <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="onRowDelete(vendeur)"></button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">Aucun vendeur créé dans votre établissement.</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr>
            <td>
              <input pInputText [(ngModel)]="seller.Login" (input)="onLoginInput()" (focus)="onLoginFocus($event)" />
              <p-overlayPanel #opLogin>
                <p [ngClass]="{'valid': isValidLogin, 'invalid': !isValidLogin}">Login doit avoir au moins 3 caractères</p>
              </p-overlayPanel>
              
            </td>
            <td>
              <input pInputText [(ngModel)]="seller.Password" (input)="onPasswordInput()" (focus)="onPasswordFocus($event)" />
              <p-overlayPanel #op>
                <p [ngClass]="{'valid': hasLowercase, 'invalid': !hasLowercase}">Au moins une lettre minuscule</p>
                <p [ngClass]="{'valid': hasUppercase, 'invalid': !hasUppercase}">Au moins une lettre majuscule</p>
                <p [ngClass]="{'valid': hasSpecialChar, 'invalid': !hasSpecialChar}">Au moins un caractère spécial</p>
                <p [ngClass]="{'valid': hasDigit, 'invalid': !hasDigit}">Au moins un chiffre</p>
                <p [ngClass]="{'valid': hasMinLength, 'invalid': !hasMinLength}">Longueur minimale de 8 caractères</p>
              </p-overlayPanel>
            </td>
            <td><input pInputText [(ngModel)]="seller.Nom" /></td>
            <td><input pInputText [(ngModel)]="seller.Prenom" /></td>
            <td>
              <input pInputText [(ngModel)]="seller.Mail" (input)="onEmailInput()" (focus)="onEmailFocus($event)"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
              <p-overlayPanel #opMail *ngIf="!isValidEmail">
                <p [ngClass]="{'valid': isValidEmail, 'invalid': !isValidEmail}">Email non valide</p>
              </p-overlayPanel>
              
            </td>
            <td>
              <button pButton pRipple type="button" icon="pi pi-plus" [disabled]="!isValidForm()" (click)="saveSeller()"></button>
            </td>
          </tr>


        </ng-template>

      </p-table>


    </div>

    



    <div>
      <p *ngIf="msgErreurLogin" style="color: red">
        {{msgErreurLogin}}
      </p>
      <p *ngIf="msgErreurMail" style="color: red">
        {{msgErreurMail}}
      </p>
      <p *ngIf="msgErreurPsw" style="color: red">
        {{msgErreurPsw}}
      </p>
    </div>

  </div>

</div>


