import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ModalComponent } from './modal/modal.component';
import { FiltreComponent } from './filtre/filtre.component';
import { Ng5SliderModule } from 'ng5-slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StatistiqueComponent } from './statistique/statistiquecomponent';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RemoveCommaPipe } from './pipe/remove-comma.pipe';
import { DialogComponent } from './dialog/dialog.component';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule as MatListModule } from '@angular/material/list';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { registerLocaleData, CommonModule } from '@angular/common';

import { AppLayoutModule } from './layout/app.layout.module';

import 'hammerjs';
import { AdministrationCampagneComponent } from './administration-campagne/administration-campagne.component';
import { CreateLotJeuxComponent } from './create-lot-jeux/create-lot-jeux.component';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FideliteComponent } from './fidelite/fidelite.component';
import { CreationCampagneComponent } from './creation-campagne/creation-campagne.component';

//import { QRCodeModule } from 'angular2-qrcode';
import { MessagesComponent } from './messages/messages.component';
import { SiteStandardComponent } from './site-standard/site-standard.component';
import { EnseigneComponent } from './enseigne/enseigne.component';
import { ProfileComponent } from './profile/profile.component';

import { NgxColorsModule } from 'ngx-colors';

import localFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { DialogAvertissementComponent } from './dialog-avertissement/dialog-avertissement.component';
import { SmartphoneDisplayComponent } from './smartphone-display/smartphone-display.component';
import { ListeEnseigneComponent } from './liste-enseigne/liste-enseigne.component';
import { VendeurComponent } from './vendeur/vendeur.component';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

registerLocaleData(localFr, 'fr-FR', localeFrExtra);

import { AppLayoutComponent } from "./layout/app.layout.component";
import { MenubarModule } from 'primeng/menubar';
import { DropdownModule } from 'primeng/dropdown';
import { AnnonceComponent } from './annonce/annonce.component';
import { NotificationComponent } from './notification/notification.component';

import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

import { TimelineModule } from 'primeng/timeline';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { EditorModule } from 'primeng/editor';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CodeValidationComponent } from './code-validation/code-validation.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AngularPinturaModule } from '@pqina/angular-pintura';
import { FileUploadModule } from 'primeng/fileupload';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { ChartModule } from 'primeng/chart';

import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        LoginComponent,
        HomeComponent,
        FiltreComponent,
        ModalComponent,
        StatistiqueComponent,
        RemoveCommaPipe,
        DialogComponent,
        AdministrationCampagneComponent,
        CreateLotJeuxComponent,
        FideliteComponent,
        CreationCampagneComponent,
        MessagesComponent,
        SiteStandardComponent,
        EnseigneComponent,
        ProfileComponent,
        DialogAvertissementComponent,
        SmartphoneDisplayComponent,
        ListeEnseigneComponent,
        VendeurComponent,
        AnnonceComponent,
    NotificationComponent,
    CodeValidationComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
      HttpClientModule,
      FlexLayoutModule,
      QRCodeModule,
      AppLayoutModule,
      NgbModule,
      Ng5SliderModule,
      ScrollingModule,
      NgxExtendedPdfViewerModule,
        FormsModule,
        ReactiveFormsModule,
        AngularPinturaModule,
        MatDialogModule,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatTabsModule,
        MatIconModule,
        ChartModule,
      FileUploadModule,
      MatCardModule,
      OverlayPanelModule,
      MatButtonModule,
      BreadcrumbModule,
      MatDatepickerModule,
      EditorModule,
      MatNativeDateModule,
      ToastModule,
      MatProgressSpinnerModule,
      StepsModule,
      NgbDatepickerModule,
      //ChartModule,
      MatDividerModule,
      ButtonModule,
      MatListModule,
      InputTextModule,
      MatSlideToggleModule,
      InputTextareaModule,
      MatSelectModule,
      InputNumberModule,
      MatTableModule,
      TableModule,
      ZXingScannerModule,
      CardModule,
      NgxColorsModule,
      DropdownModule,
      TimelineModule,
      DialogModule,
      MenubarModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([
          { path: '', component: LoginComponent, pathMatch: 'full' },
          {
            path: 'home', component: AppLayoutComponent,
            children: [
              { path: 'Dashboard', component: HomeComponent },
              { path: 'jeux', component: CreateLotJeuxComponent },
              { path: 'fidelite', component: FideliteComponent },
              { path: 'enseigne', component: EnseigneComponent },
              { path: 'siteStandard', component: SiteStandardComponent },
              { path: 'profile', component: ProfileComponent },
              { path: 'annonce', component: AnnonceComponent },
              { path: 'notification', component: NotificationComponent },
              { path: 'application_pro', component: VendeurComponent },
              { path: 'application_Utilisateur', component: CodeValidationComponent },
              { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
              ]
          },
          { path: '**', redirectTo: '/not-found' }
            
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
    providers: [
      MatDatepickerModule,
      MessageService,
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
