import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { DialogComponent } from '../dialog/dialog.component';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { urlConfig } from '../service/urlConfig.service';
import { environment } from '../../environments/environment';
import { AppComponent } from '../app.component';

import { data0, data1, data2, data3, areaData } from './datasource';

/*import { Browser } from '@syncfusion/ej2-base';*/

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  contrat = { siteWeb: false, animationClientele: false, communication: false };

  //headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });
  public static lastEnseigne: any;

  env = environment;


  /*lstEnseigne: any;*/
  enseigneSelected: any;
  indexClicked = 0;

  feeBack: any;

  chartOptions: any = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top'
      }
    }
  };

  dataClientsParJour: any;
  dataClientsParSemaine: any;
  dataClientsParMois: any;
  dataGainsParJour: any;
  dataGainsConsommesParJour: any;
  dataPointsParJour: any;
  dataClientsAujourdhuiParHeure: any;
  dataPointsAttribuesAujourdhuiParHeure: any;
  dataGainsConsommesAujourdhuiParHeure: any;

  //public primaryXAxis?: Object;
  //public chartData?: Object[];
  //public title?: string;
  //public primaryYAxis?: Object;

  //public primaryXAxis4?: Object;
  //public chartData4?: Object[];
  //public title4?: string;
  //public primaryYAxis4?: Object;


  //public primaryXAxis5?: Object;
  //public title5?: string;
  //public primaryYAxis5?: Object;
  //public marker5?: Object;
  //public series?: Object;
  //public chartData5?: Object[]; public chartData6?: Object[]; public chartData7?: Object[];
  //public chartData8?: Object[];


  //public primaryXAxis9?: Object;
  //public chartData9?: Object[];
  //public title9?: string;
  //public primaryYAxis9?: Object;


  //public primaryXAxis3?: Object;
  //public chartData3?: Object[];
  //public title3?: string;
  //public primaryYAxis3?: Object;
  //public tooltip?: Object;
  //public cornerRadius: Object = {
  //  topLeft: Browser.isDevice ? 4 : 6, topRight: Browser.isDevice ? 4 : 6
  //};
  //public paretoOptions: Object = {
  //  marker: {
  //    visible: true,
  //    isFilled: true,
  //    width: 7,
  //    height: 7
  //  },
  //  dashArray: '3,2',
  //  width: 2,
  //  fill: '#F7523F'
  //};

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private app: AppComponent) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }

    //this.chartData = [{ x: 1, y: 20 }, { x: 2, y: 22 }, { x: 3, y: 0 }, { x: 4, y: 12 }, { x: 5, y: 5 },
    //{ x: 6, y: 15 }, { x: 7, y: 6 }, { x: 8, y: 12 }, { x: 9, y: 20 }, { x: 10, y: 7 }];
    //this.primaryYAxis = {
    //  title: 'Runs',
    //  stripLines: [
    //    { start: 15, end: 22, text: 'Good', color: '#ff512f', visible: true, zIndex: 'Behind', opacity: 0.5 },
    //    { start: 8, end: 15, text: 'Medium', color: 'pink', opacity: 0.5, visible: true, zIndex: 'Behind' },
    //    { start: 0, end: 8, text: 'Not enough', color: 'skyblue', opacity: 0.5, visible: true, zIndex: 'Behind' }]
    //};
    //this.primaryXAxis = {
    //  title: 'Overs'
    //};
    //this.title = 'India Vs Australia 1st match';

    //this.chartData4 = [
    //  { x: "South Korea", y: 39.4 }, { x: "India", y: 61.3 }, { x: "Pakistan", y: 20.4 },
    //  { x: "Germany", y: 65.1 }, { x: "Australia", y: 15.8 }, { x: "Italy", y: 29.2 },
    //  { x: "France", y: 44.6 }, { x: "Saudi Arabia", y: 9.7 }, { x: "Russia", y: 40.8 },
    //  { x: "Mexico", y: 31 }, { x: "Brazil", y: 75.9 }, { x: "China", y: 51.4 }
    //];
    //this.primaryXAxis4 = {
    //  title: 'Countries',
    //  valueType: 'Category',
    //  labelIntersectAction: 'Hide'
    //};
    //this.primaryYAxis4 = {
    //  minimum: 0, maximum: 80, interval: 10,
    //  title: 'People(in millions)'
    //};
    //this.title4 = 'Internet Users';


    //this.primaryXAxis5 = {
    //  interval: 1, valueType: 'Category'
    //};
    //this.primaryYAxis5 =
    //{
    //  title: 'Expense',
    //  interval: 100,
    //  labelFormat: '${value}',
    //},
    //  this.chartData5 = data0;
    //this.chartData6 = data1;
    //this.chartData7 = data2;
    //this.chartData8 = data3;
    //this.marker5 = { visible: true };

    //this.chartData9 = areaData;
    //this.primaryXAxis9 = {
    //  title: 'Year',
    //  minimum: 1900, maximum: 2000, interval: 10,
    //  edgeLabelPlacement: 'Shift'
    //};
    //this.primaryYAxis9 = {
    //  minimum: 2, maximum: 5, interval: 0.5,
    //  title: 'Sales Amount in Millions'
    //};
    //this.title9 = 'Average Sales Comparison';



    //this.chartData3 = [
    //  { x: 'Button Defect', y: 23 }, { x: 'Pocket Defect', y: 16 },
    //  { x: 'Collar Defect', y: 10 }, { x: 'Cuff Defect', y: 7 },
    //  { x: 'Sleeve Defect', y: 6 }, { x: 'Other Defect', y: 2 }
    //];
    //this.primaryXAxis3 = {
    //  interval: 1,
    //  valueType: 'Category',
    //  majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
    //  majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
    //  lineStyle: { width: 0 },
    //  labelIntersectAction: 'Rotate90'
    //};
    //this.primaryYAxis3 = {
    //  title: 'Frequency of Occurence',
    //  minimum: 0,
    //  maximum: 25,
    //  interval: 5,
    //  lineStyle: { width: 0 },
    //  majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
    //  minorGridLines: { width: 1 }, minorTickLines: { width: 0 }
    //};
    //this.tooltip = { enable: true, shared: true, format: '${series.name} : <b>${point.y}</b>' };
    //this.title3 = 'Defects in Shirts';


  }

  ngAfterViewInit() {
    if (HomeComponent.lastEnseigne != undefined && HomeComponent.lastEnseigne != null) {
      this.enseigneClick(HomeComponent.lastEnseigne);
    }

    this.app.ensSelected.subscribe((ens: any) => {
      HomeComponent.lastEnseigne = ens;
      this.enseigneClick(ens);
    });

  }

  getFeedBack() {
    this.http.post(urlConfig.getUrl("getfeedback"), HomeComponent.lastEnseigne).subscribe((res: any) => {
      if (res.success) {
        this.feeBack = res.data;
        this.initializeChartData();        
      }
    });
  }

  enseigneClick(ens) {
    this.enseigneSelected = ens;
    this.getFeedBack();

    this.contrat = {
      siteWeb: this.enseigneSelected?.IdContratsNavigation?.SiteWeb,
      animationClientele: this.enseigneSelected?.IdContratsNavigation?.AnimationClientele,
      communication: this.enseigneSelected?.IdContratsNavigation?.Communication
    };

  }

  initializeChartData() {
    const hoursLabels = Array.from({length: 24}, (_, i) => `${i}h`);
    //const daysLabels = this.feeBack.ClientsParJour.map((_, i) => `J${i + 1}`);
    const today = new Date();

    const daysLabels = Array.from({ length: this.feeBack.ClientsParJour.length }, (_, i) => {
      const dayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
      return `${dayDate.getDate().toString().padStart(2, '0')}/${(dayDate.getMonth() + 1).toString().padStart(2, '0')}`;
    }).reverse();

    const weeksLabels = Array.from({ length: this.feeBack.ClientsParSemaine.length }, (_, i) => {
      const endOfWeek = new Date(new Date().setDate(new Date().getDate() - (i * 7)));
      const startOfWeek = new Date(new Date(endOfWeek).setDate(endOfWeek.getDate() - 6));

      return `${startOfWeek.getDate().toString().padStart(2, '0')}-${endOfWeek.getDate().toString().padStart(2, '0')}/${(endOfWeek.getMonth() + 1).toString().padStart(2, '0')}`;
    }).reverse();


    //const weeksLabels = this.feeBack.ClientsParSemaine.map((_, i) => `S${i + 1}`);
    //const monthsLabels = this.feeBack.ClientsParMois.map((_, i) => `M${i + 1}`);

    const monthsLabels = Array.from({ length: 12 }, (_, i) => {
      const date = new Date();
      date.setMonth(date.getMonth() - (11 - i), 1); // Ajuste pour obtenir les 12 derniers mois
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    });


    this.dataClientsParJour = this.createLineChartData(daysLabels, this.feeBack.ClientsParJour, 'Nouveaux clients Sur 2 semaines', '#42A5F5');
    this.dataClientsParSemaine = this.createLineChartData(weeksLabels, this.feeBack.ClientsParSemaine, 'Nouveaux clients Sur 3 mois', '#66BB6A');
    this.dataClientsParMois = this.createLineChartData(monthsLabels, this.feeBack.ClientsParMois, 'Nouveaux clients Sur 12 mois', '#FFA726');
    this.dataGainsParJour = this.createLineChartData(daysLabels, this.feeBack.GainsParJour, 'Lots gagnés sur 2 semaines', '#9CCC65');
    this.dataGainsConsommesParJour = this.createBarChartData(daysLabels, this.feeBack.GainsConsommesParJour, 'Lots retiré sur 2 semaines');
    this.dataPointsParJour = this.createPieChartData(daysLabels, this.feeBack.PointsParJour, 'Points ajoutés sur 2 semainess');
    this.dataClientsAujourdhuiParHeure = this.createBarChartData(hoursLabels, this.feeBack.ClientsAujourdhuiParHeure, 'Nouveaux clients');
    this.dataPointsAttribuesAujourdhuiParHeure = this.createBarChartData(hoursLabels, this.feeBack.PointsAttribuesAujourdhuiParHeure, 'Points attribués');
    this.dataGainsConsommesAujourdhuiParHeure = this.createBarChartData(hoursLabels, this.feeBack.GainsConsommesAujourdhuiParHeure, 'Lots retiré');
  }

  createLineChartData(labels, data, label, borderColor) {
    return {
      labels,
      datasets: [
        {
          label,
          data,
          fill: false,
          borderColor,
          tension: 0.1
        }
      ],
      options: this.chartOptions
    };
  }

  createBarChartData(labels, data, label) {
    return {
      labels,
      datasets: [
        {
          label,
          backgroundColor: '#42A5F5',
          data
        }
      ],
      options: this.chartOptions
    };
  }

  createPieChartData(labels, data, label) {
    return {
      labels,
      datasets: [
        {
          label,
          backgroundColor: '#42A5F5',
          data
        }
      ],
      options: this.chartOptions
    };
  }

  sumArrayElements(array: number[]): number {
    return array.reduce((a, b) => a + b, 0);
  }


}
