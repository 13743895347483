import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { urlConfig } from '../service/urlConfig.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [NgbModal]
})
export class LoginComponent implements OnInit {

  //urlLogin: string = "https://207.180.246.59:57429/api/InteractionUser/Login";
  //urlLogin: string = "https://localhost:5005/api/InteractionUser/Login";

  login: string = "";
  password: string = "";
  stLogin: string = "";
  stPassword: string = "";

  public static token: string;

  hidePassword = true;

  errorLogin: boolean = false;
  erroLoginMessage: string = "";
  showDialog = false;

  constructor(private http: HttpClient, private router: Router, private _modalService: NgbModal, private dialog: MatDialog, private app: AppComponent) {
  }

  ngOnInit() {
    //var tok = localStorage.getItem('Token');
    //var date = localStorage.getItem('Date');
    //var expire = localStorage.getItem('Expire_In');
    //if (tok && date && expire) {
    //  var now = Date.now();
    //  var numberDate = parseInt(date);
    //  var numberExpire = parseInt(expire);

    //  if (numberDate && numberExpire) {
    //    var limiteDate = numberDate + numberExpire;
    //    var dateExpire = new Date(1970, 0, 1).setSeconds(limiteDate);
    //    if (dateExpire >= Date.now()) {
    //      NavMenuComponent.connected = true;
    //      NavMenuComponent.userLog = localStorage.getItem('user');
    //      this.router.navigate(['/home']);
    //    }
    //  }
    //}

    //this.stLogin = localStorage.getItem('login');
    //this.stPassword = localStorage.getItem('password');

    //if (this.stLogin && this.stPassword) {
    //  this.login = this.stLogin;
    //  this.password = this.stPassword;

    //  this.onSubmit();
    //}

  }

  onSubmit() {
    //this.dialog.open(DialogComponent, {
    //  data: {
    //    title: "Connexion",
    //    message: 'Chargement en cours !!!',
    //    enableLoader: true,
    //    congrat: false
    //  }
    //});

    this.showDialog = true;

    this.http.post(urlConfig.getUrl("login"), { login: this.login, password: this.password, plateforme: "site" }).subscribe((res: any) => {
      this.dialog.closeAll();
      if (res.success) {
        LoginComponent.token = res.data.token.token_type + ' ' + res.data.token.access_token;
        //localStorage.setItem('Token', LoginComponent.token);
        //localStorage.setItem('Expire_In', res.data.expires_in);
        //localStorage.setItem('Date', Date.now().toString());
        //localStorage.setItem('user', this.login.toString());

        //if (!(this.stLogin && this.stPassword)) {
        //  localStorage.setItem('login', this.login);
        //  localStorage.setItem('password', this.password);
        //}

        NavMenuComponent.connected = true;
        NavMenuComponent.userLog = this.login;
        NavMenuComponent.usrConnected = res.data.user;
        this.showDialog = false;
        this.router.navigate(['home']);
      }
      else {
        this.errorLogin = true;
        this.erroLoginMessage = res.message;
        this.showDialog = false;
      }
    }, error => {
      this.showDialog = false;
        //this.dialog.closeAll();
      });

  }

  open(id: number) {
    const modalRef = this._modalService.open(ModalComponent, { centered: true, size: 'lg' });

    if (id == 0) {
      modalRef.componentInstance.titre = 'Création de compte';
    }
    else {
      modalRef.componentInstance.titre = 'Mot de passe oublié';
    }

    modalRef.componentInstance.id = id;
  }

}
