<div class="globalContainer">

  

  <div [class]="enableErreur">
    <div class="leftContentAlerte">
      <div class="animate__animated animate__tada animate__repeat animate__infinite">
        <mat-icon class="example-tab-icon" style="color: white;font-size: xx-large">warning_amber</mat-icon>
      </div>
      <div style="color: white">
        Alerte
      </div>
    </div>
    <div>
      {{messageErreur}}
    </div>
  </div>

  <div class="homeContainer">
      <div class="flex flex-column justify-content-between align-items-center">
        <div class="flex flex-row justify-content-evenly align-items-center">
          <span class="ui-message ui-messages-error" style="color: red; font-size: 14px; margin-bottom: 10px; background-color: white; border-radius: 16px; padding: 1em; border: 1px solid;">
            Information sur les conditions de validation des gains qui apparaîtra au niveau jeux du client. <br /> Votre jeux ne sera pas activé si vous ne renseignez pas cette information
          </span>
          <div class="flex flex-column align-items-center justify-content-between" style="margin-left: 1em;">
            <span>Conditions de validation des gains :</span>
            <textarea rows="4" cols="50" pInputTextarea [(ngModel)]="condValidation"></textarea>
            <small style="color: lightslategray"><strong>Ex. : Gain attribué lors de votre prochain achat d'un montant minimum de 10€.</strong></small>
            <p-button *ngIf="condValidation != enseigneSelected.ConditionValide" label="Enregistrer" (click)="validCond()"></p-button>
          </div>
          
        </div>


        <div class="card" style="width: 100%; height: 54vh; margin-top:1em;">
          <p-table [value]="lotsEnseigne" [scrollable]="true" scrollHeight="100vh" *ngIf="contrat.animationClientele" editMode="row" dataKey="Nom">
            <ng-template pTemplate="header">
              <tr>
                <th>Nom</th>
                <th>Nombre</th>
                <th>Chance</th>
                <th>Valeur</th>
                <th>Minimum achat</th>
                <th>Gagné ce mois-ci</th>
                <th>Action</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-lot let-editing="editing" let-idx="rowIndex">
              <tr [pEditableRow]="lot" [ngClass]="{'new-lot': isNewLot(lot)}">
                <!-- Nom -->
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="putLotTmp.Nom" readonly style="cursor: no-drop">
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{lot.Nom}}
                    </ng-template>
                  </p-cellEditor>
                </td>

                <!-- Nombre -->
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-inputNumber [(ngModel)]="putLotTmp.Nombre" [min]="0" [step]="1" inputMode="numeric"></p-inputNumber>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{lot.Nombre}}
                    </ng-template>
                  </p-cellEditor>
                </td>

                <!-- Chance -->
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <!--<p-inputNumber [(ngModel)]="putLotTmp.Chance"></p-inputNumber>-->
                      <p-inputNumber [(ngModel)]="putLotTmp.Chance" inputId="minmax" mode="decimal" [min]="0" [max]="100" [minFractionDigits]="0" [maxFractionDigits]="9" suffix=" %"> </p-inputNumber>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{lot.Chance}}
                    </ng-template>
                  </p-cellEditor>
                </td>

                <!-- Valeur -->
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <!--<p-inputNumber [(ngModel)]="putLotTmp.Valeur" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>-->
                      <p-inputNumber [(ngModel)]="putLotTmp.Valeur" inputId="minmaxfraction" mode="decimal" [min]="0" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{lot.Valeur}}
                    </ng-template>
                  </p-cellEditor>
                </td>

                <!-- Minimum achat -->
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <!--<p-inputNumber [(ngModel)]="putLotTmp.MinAchat"></p-inputNumber>-->
                      <p-inputNumber [(ngModel)]="putLotTmp.MinAchat" inputId="minmaxfractionMinAchat" mode="decimal" [min]="0" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{lot.MinAchat}}
                    </ng-template>
                  </p-cellEditor>
                </td>

                <!-- Nombre gagné -->
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="putLotTmp.Gagne" readonly style="cursor: no-drop">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div [ngClass]="{'lowstock': (lot.Gagne > 0 && lot.Gagne < lot.Nombre),'instock': lot.Gagne === lot.Nombre}">
                        {{lot.Gagne}}
                      </div>
                    </ng-template>
                  </p-cellEditor>

                </td>

                <td>
                  <div class="flex align-items-center justify-content-center gap-2">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="editLot(lot, idx)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="putLot()" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="initEditLot()" class="p-button-rounded p-button-text p-button-danger"></button>
                    <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="deleteLot(lot)"></button>
                  </div>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="footer"  >
              <tr *ngIf="tailleLotEns()">
                <td><input type="text" pInputText [(ngModel)]="createLot.Nom" /></td>
                <td>
                  <p-inputNumber inputId="integeronly" [(ngModel)]="createLot.Nombre"  [min]="0" [step]="1" inputMode="numeric"> </p-inputNumber>
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="createLot.Chance" inputId="minmax" mode="decimal" [min]="0" [max]="100" [minFractionDigits]="0" [maxFractionDigits]="9" suffix=" %"> </p-inputNumber>
                </td>
                <td>
                  <!--<p-inputNumber inputId="integeronly" [(ngModel)]="createLot.Valeur"> </p-inputNumber>-->
                  <p-inputNumber [(ngModel)]="createLot.Valeur" inputId="minmaxfraction" mode="decimal" [min]="0" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>
                </td>
                <td>
                  <!--<p-inputNumber inputId="integeronly" [(ngModel)]="createLot.MinAchat"> </p-inputNumber>-->
                  <p-inputNumber [(ngModel)]="createLot.MinAchat" inputId="minmaxfractionMinAchat" mode="decimal" [min]="0" [minFractionDigits]="0" [maxFractionDigits]="2"> </p-inputNumber>
                </td>
                <td>
                  <p-inputNumber inputId="integeronly" [(ngModel)]="createLot.NombreGagne" [style.display]="idxLot == idxEdit ? '' : 'none'"> </p-inputNumber>
                </td>
                <td>
                  <button pButton pRipple type="button" icon="pi pi-plus" (click)="addLot()"></button>
                  <!--<mat-icon class="example-tab-icon" (click)="addLot()" style="color: blue;cursor: pointer;">add_circle</mat-icon>-->
                </td>
              </tr>
              <!--<td colspan="5">
        <div class="flex align-items-center justify-content-center">
            <span *ngIf="chancePerdre != 0" style="color: red;">Vos clients ont {{100 - chancePerdre}} % de chance de gagner</span>
            <span *ngIf="chancePerdre == 0" style="color: green;">Vos clients ont 100 % de chance de gagner</span>
        </div>
      </td>-->
              <td colspan="5">
                <div class="flex align-items-center justify-content-center">
                  <span *ngIf="chancePerdre != 0" style="color: red;">Vos clients ont {{100 - chancePerdre}} % de chance de gagner</span>
                  <span *ngIf="chancePerdre == 0" style="color: green;">Vos clients ont 100 % de chance de gagner</span>
                </div>
              </td>
              <td colspan="5">
                <div class="flex align-items-center justify-content-center">
                  <button *ngIf="lstNewlotsEnseigne?.length > 0" pButton pRipple type="button" pSaveEditableRow label="Enregistrer" (click)="saveLot()" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                </div>
              </td>
            </ng-template>

          </p-table>



          <!--<p-table [value]="[1]" [tableStyle]="{'min-width': '60rem'}" *ngIf="contrat.animationClientele">
    <ng-template pTemplate="header">
      <tr>
        <th>Nom</th>
        <th>Nombre</th>
        <th>Chance</th>
        <th>Valeur</th>
        <th>Minimum achat</th>
        <th>Nombre gagné</th>
        <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" [style.display]="chancePerdre > 0 ? '' : 'none'">

    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        <span *ngIf="chancePerdre != 0" style="color: red; ">Vos clients ont {{100 - chancePerdre}} % de chance de gagner</span>
        <span *ngIf="chancePerdre == 0" style="color: green; ">Vos clients ont 100 % de chance de gagner</span>
      </div>
    </ng-template>
  </p-table>-->

          <div *ngIf="!contrat.animationClientele" class="notGame">
            <div class="titleNotGame">Les Jeux permettent l’acquisition de nouveaux clients.</div>
            <div class="textNotGame">
              Faîtes gagner des lots ou des remises que vous aurez déterminé.
              Les utilisateurs sont invités à laisser un avis google avant chaque jeu.Leurs avis sont importants pour apporter une meilleure visibilité de votre commerce.
              7 clients sur 10 sont sensibles aux avis Google.C’est le point essentiel pour dynamiser votre flux clientèle et votre business.
            </div>

            <div class="textNotGame">
              Ce service fait partie de l’offre animation clientèle.
            </div>

            <div class="textNotGame">
              Pour bénéficier de cette offre ou visualiser une démo contacter nous au 05.61.446.449 ou à contact@flytagger.com
            </div>
          </div>

        </div>

      </div>
  </div>
</div>





